import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const insights_inst_key = process.env.REACT_APP_INSIGHTS_INST_KEY_REACT;
const endpoint_suffix = process.env.REACT_APP_INSIGHTS_ENDPOINT_SUFFIX;
const ingestion_endpoint = process.env.REACT_APP_INSIGHTS_INGESTION_ENDPOINT;
const connection_string_inst = "InstrumentationKey=" + insights_inst_key + ";EndpointSuffix=" + endpoint_suffix + ";IngestionEndpoint=" + ingestion_endpoint;
const appInsights = new ApplicationInsights({ config: {
  connectionString: connection_string_inst
  /* ...Other Configuration Options... */
} });
appInsights.loadAppInsights();
appInsights.setAuthenticatedUserContext('', null, true);
appInsights.trackPageView(); 
//import registerServiceWorker from './registerServiceWorker';

document.addEventListener("DOMContentLoaded", function(event) {
  ReactDOM.render(<App />, document.getElementById('root'));
  if (window.sessionStorage.getItem('msal.client.info') != undefined) {
    // we should have a username if we have the above session variable
    let user_email = window.sessionStorage.getItem('user_email');
    appInsights.setAuthenticatedUserContext(user_email, null, true);
  }
  else {
    window.sessionStorage.setItem('user_email', null);
    appInsights.setAuthenticatedUserContext(null, null, true);
  }
});
//registerServiceWorker();
