import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MaeraResponse from './components/MaeraResponse';
import './components/Components.css' ;
import 'react-select/dist/react-select.css';
import Button from '@material-ui/core/Button';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Forward from '@material-ui/icons/Forward';
import Create from '@material-ui/icons/Create';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import Select from 'react-select';

const drawerWidth = 240;
var Responses;
if (process.env.NODE_ENV !== 'production'){
  /* Responses = require('./test/MockMaeraResponse.jsx').default; */
  Responses = require('./functions/Responses.jsx').default;
} else {
  Responses = require('./functions/Responses.jsx').default;
}

const styles = theme => ({
  root: {
    width: '100%',
    height: 430,
    marginTop: theme.spacing(3),
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#27b9e6',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    backgroundColor: '#27b9e6',
    background: 'none',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: drawerWidth,
    backgroundColor: '#F0F0F0',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 60,
    overflowX: 'hidden',
    backgroundColor: '#F0F0F0',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // Make the items inside not wrap when transitioning:
  drawerInner: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 24,
    height: '100%',
    marginTop: 56,
  },
});

class MiniDrawer extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      categoryOptions: [],
      locales: [],
      locale: "",
      queue: "",
      userQueue: ""
    };

    this.updateQueue = this.updateQueue.bind(this);
    this.fetchUserQueueName = this.fetchUserQueueName.bind(this);
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleDropdown = () => {
    this.child.toggleDropdown()
  }

  toggleEdit = () => {
    this.child.toggleEdit()
  }

  toggleInfo = () => {
    this.child.toggleInfo()
  }

  toggleResponse = () => {
    this.child.toggleResponse()
  }

  /**
   * Fetches the name of the queue that is assigned to the signed in user
   *   create a new instance of 'Responses' called 'responses'
   *   Makes a call to 'ResponsesMSD.jsx' to get the queue name from the backened
   *   use this.setState to save the queue name in state
   *   use this.setState to set the current 'queue' to
   */

  async fetchUserQueueName() {
    const responses = new Responses()
    try {
      const userQueue = await responses.getUserQueueName()
      this.setState({ userQueue })
      this.setState({ queue: this.state.userQueue })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * On component mount:
   *  fetch the queue name of the signed in user
   *  make a call to the backened to fetch the categories
   *  categories include categoryOptions, queue, locales, and locale
   */

  async componentDidMount() {
    let responses = new Responses()
    let cats
    try {
      this.fetchUserQueueName()
      cats = await responses.getCategories()
      this.setState({
        categoryOptions: cats.categories,
        queue: cats.queue,
        locales: cats.locales,
        locale: cats.locale,
      })
    } catch (err) {
      console.error(err.message)
    }
  }

  /**
   * @param {string : selected queue name} queue
   * This gets fired from the onChange event listener of the Select compnent:
   *   use this.setState to set the queue
   *   call 'changeQueue' in the MaeraResponse component passing it the queue as an argument
   */

  updateQueue(queue) {
    this.setState({ queue })
    this.child.changeQueue(queue)
  }

  render() {
    let responses = new Responses()
    const { classes, theme } = this.props
    const categories = this.state.categoryOptions.map((l) => {
      return { label: l, value: l }
    })
    const mappedLocales = this.state.locales.map((l) => {
      return { label: l, value: l }
    })

    return (
      <div>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            <Toolbar disableGutters={!this.state.open}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
              <div className="categoriesDropDown">
                <Typography variant="title" color="inherit" noWrap>
                  Maera
                </Typography>
                <span className="flexRow">
                  <Select
                    value={this.state.queue}
                    options={mappedLocales}
                    onChange={this.updateQueue}
                    simpleValue
                    clearable={false}
                    autosize={false}
                    style={{ width: "90%" }}
                    placeholder={this.state.userQueue}
                  />
                </span>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.drawerInner}>
              <div className={classes.drawerHeader}>
                <IconButton
                  onClick={this.handleDrawerClose}
                  className="closeDrawer"
                >
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <Button className="fullWidth" onClick={this.toggleDropdown}>
                <ArrowDownward />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dropdown Toggle
              </Button>
              <Divider />
              <Button className="fullWidth" onClick={this.toggleEdit}>
                <Create />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                Edit Toggle
              </Button>
              <Divider />
              <Button className="fullWidth" onClick={this.toggleResponse}>
                <Forward />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                Response Toggle
              </Button>
              <Divider />
            </div>
          </Drawer>
          <main className={classes.content}>
            <MaeraResponse
              onRef={(ref) => (this.child = ref)}
              submitCase={responses.submitCase}
              getCase={responses.getCase}
              getMaeraResponse={responses.getMaeraResponse}
              skipCase={responses.skipCase}
              updateQueue={responses.updateQueue}
              queue={this.state.queue}
              userQueue={this.state.userQueue}
              fetchUserQueueName={this.fetchUserQueueName}
            />
          </main>
        </div>
      </div>
    )
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(MiniDrawer)
