import React from 'react';
import ListHolder from './ListHolder.jsx';
import CGITheme from '../themes/CGITheme.jsx';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class MaeraResponse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: { labels: [], locales: [], default_locale: "India_EN" },
      case__c: {
        Internal_Comments__c: "",
        Public_Response__c: "",
        Contact__r: {},
      },
      skip_count: 1
    }

    this.changeQueue = this.changeQueue.bind(this)
  }

  resetState = () => {
    this.setState({
      data: { labels: [], locales: [], default_locale: "India_EN" },
      case__c: {
        Internal_Comments__c: "",
        Public_Response__c: "",
        Contact__r: {},
      },
    })
  }

  getUrlVars = () => {
    var vars = {}
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value
      }
    )
    return vars
  }

  closeCase = async (data) => {
    let queueData = `{"queue": "${this.props.queue}"}`;
    let skipCount = `{"skip_count": "${this.state.skip_count}"}`;
    data = { ...data, ...JSON.parse(queueData), ...JSON.parse(skipCount) }
    var submitCase = this.props.submitCase
    let myCase
    try {
      myCase = await submitCase(data)
      //this.changeQueue(this.props.queue)
    } catch (err) {
      console.error(err.message)
    }
    if (myCase && myCase != 'None') {
      this.setState({ case__c: myCase })
    try {
      let getMaeraResponse = this.props.getMaeraResponse
      const maeraResponse = await getMaeraResponse(
        myCase.Description__c,
        myCase.Country_Account_Name__c
      )
      this.setState({ data: JSON.parse(maeraResponse) })
    } catch (err) {
      console.error(err.message)
    }
  } else {
    this.resetState()
  }
  }

  getCase = async () => {
    this.setState({ buttonDisabled: true })
    let myCase
    const urlParam = this.getUrlVars()["id"] || "null"
    try {
      var getCase = this.props.getCase
      myCase = await getCase(urlParam)
      this.props.fetchUserQueueName()
    } catch (err) {
      console.error("MESSAGE IN GETCASE Error: " + err.message)
    }
    if (myCase && myCase != 'None') {
      this.setState({ case__c: myCase })
      this.setState({ skip_count: this.state.skip_count + 1 })
      try {
        let getMaeraResponse = this.props.getMaeraResponse
        const maeraResponse = await getMaeraResponse(
          myCase.Description__c,
          myCase.Country_Account_Name__c
        )
        this.setState({ data: JSON.parse(maeraResponse) })
      } catch (err) {
        console.error(err.message)
      }
    } else {
      this.resetState()
    }
    this.setState({ buttonDisabled: false })
  }

  skipCase = async (data) => {
    let queueData = `{"queue": "${this.props.queue}"}`;
    let skipCount = `{"skip_count": "${this.state.skip_count}"}`;
    data = { ...data, ...JSON.parse(queueData), ...JSON.parse(skipCount) }
    var skipCase = this.props.skipCase
    let myCase
    try {
      myCase = await skipCase(data)
    } catch (err) {
      console.error(err.message)
    }
    if (myCase && myCase != 'None') {
      this.setState({ case__c: myCase })
      this.setState({ skip_count: this.state.skip_count + 1 })
      try {
        let getMaeraResponse = this.props.getMaeraResponse
        const maeraResponse = await getMaeraResponse(
          myCase.Description__c,
          myCase.Country_Account_Name__c
        )
        this.setState({ data: JSON.parse(maeraResponse) })
      } catch (err) {
        console.error(err.message)
      }
    } else {
      this.resetState()
    }
    //this.getCase()
    //this.setState({ buttonDisabled: false })
  }

  /**
   * @param {string : queue name} queue
   * Recieves the queue from MiniDrawer.jsx and updates the case
   *   assign the variable 'updateQueue' to this.props.updateQueue
   *   make a call to update queue to retrieve the id and then the case data
   *   use this.setState to save the new case in state
   *   make a call to Maera Core to update the reference data
   */

  async changeQueue(queue) {
    const updateQueue = this.props.updateQueue
    const res = await updateQueue(queue)
    this.setState({ case__c: res })
    this.setState({ skip_count: 1 })
    //console.log(this.state)
    if (this.state.case__c != undefined) {
      try {
        const getMaeraResponse = this.props.getMaeraResponse
        const maeraResponse = await getMaeraResponse(
          this.state.case__c.Description__c,
          this.state.case__c.Country_Account_Name__c
        )
        this.setState({ data: JSON.parse(maeraResponse) })
        this.setState({ skip_count: 2 })
      } catch (err) {
        console.error(err.message)
      }
    }
  }

  async componentDidMount() {
    this.getCase()
  }

  componentDidMount() {
    this.getCase()
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  toggleDropdown = () => {
    if (this.state.data.labels[0] && this.state.case__c != undefined) {
      this.child.toggleDropdown()
    }
  }

  toggleEdit = () => {
    if (this.state.data.labels[0] && this.state.case__c != undefined) {
      this.child.toggleEdit()
    }
  }

  toggleInfo = () => {
    this.child.toggleResponse()
    this.child.updateResponseSize()
    this.child.toggleResponse()
    this.child.toggleInfo()
  }

  toggleResponse = () => {
    if (this.state.data.labels[0] && this.state.case__c != undefined) {
      this.child.toggleResponse()
    }
  }
  getButtonText = () =>
    this.state.buttonDisabled
      ? "Loading..."
      : "No Cases Found - Click to Reload"

  render() {
    if (this.state.data.labels[0] && this.state.case__c != undefined) {
      return (
        <MuiThemeProvider theme={CGITheme}>
          <ListHolder
            data={this.state.data}
            onRef={(ref) => (this.child = ref)}
            locale={this.state.data.default_locale}
            case__c={this.state.case__c}
            closeCase={this.closeCase}
            skipCase={this.skipCase}
          />
        </MuiThemeProvider>
      )
    } else
      return (
        <div>
          <Button onClick={this.getCase} disabled={this.state.buttonDisabled}>
            {this.getButtonText()}
          </Button>
        </div>
      )
  }
}

MaeraResponse.propTypes = {
  submitCase: PropTypes.func.isRequired,
  getMaeraResponse: PropTypes.func.isRequired,
  getCase: PropTypes.func.isRequired,
  skipCase: PropTypes.func.isRequired,
}

export default MaeraResponse
