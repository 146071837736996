import { Component } from "react";
import {
    msalApp,
    requiresInteraction,
    GRAPH_SCOPES,
    APP_SCOPES
} from "./auth-utils";

const API_ENDPOINT = process.env.REACT_APP_API_URL;
const axios = require('axios');

export default class APIService extends Component {
	/* Get API Data */
	async getAPIData(uri) {
		var apiDataResponse = this.getAPIDataAxios(uri);
	    
	    return apiDataResponse;
	}
	  
	  /* Get data - using Axios*/
	  async getAPIDataAxios(uri) {
		/* Axios */
    	var apiUrl = `${API_ENDPOINT}${uri}`;
    	var tokenData = await this.fetchAccessToken();
    	
    	var apiDataResponse;

		await axios
		  .get(apiUrl, {
			  headers: {
		        'Content-Type': 'application/json',
		        'Authorization': `Bearer ${tokenData.accessToken}`
			  }
		   })
		  .then(response => {
		    apiDataResponse = response.data;
		  })
		  .catch(error => {
		    console.error('API Response Error:', error);
		  });
		  return apiDataResponse;
	  }

	  /* post API Data */
	  async postAPIData(uri, postData) {
	    var apiDataResponse = this.postAPIDataAxios(uri, postData);
	    return apiDataResponse;
	  }
	  
	  /* Get data - using Axios*/
	  async postAPIDataAxios(uri, postData) {
		/* Axios */
    	var apiUrl = `${API_ENDPOINT}${uri}`;
    	// TODO - Can access token be cached?
    	var tokenData = await this.fetchAccessToken();
    	
    	var apiDataResponse;

		await axios
		  .post(apiUrl, postData, {
			  headers: {
		        'Content-Type': 'application/text',
		        'Accept': 'application/json',
		        'Authorization': `Bearer ${tokenData.accessToken}`,
				'Access-Control-Allow-Origin': '*'
			  }
		   })
		  .then(response => {
		    apiDataResponse = response.data;
		  })
		  .catch(error => {
		    console.error('API Response Error:', error);
		  });
		  
		  return apiDataResponse;
	  }
	  	  
	  /* Fetch Access Token*/
	  async fetchAccessToken() {  
	    const accessTokenRequest = {  
	     // scopes: [GRAPH_SCOPES.OPENID,GRAPH_SCOPES.PROFILE,GRAPH_SCOPES.USER_READ,APP_SCOPES.API]
	     scopes: [GRAPH_SCOPES.OPENID,APP_SCOPES.API]
	    }  
	    const redirect = false;
        return msalApp.acquireTokenSilent(accessTokenRequest).catch(error => {
            // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
            // due to consent or interaction required ONLY
            if (requiresInteraction(error.errorCode)) {
                return redirect
                    ? msalApp.acquireTokenRedirect({
                        ...accessTokenRequest,
                        redirectUri: process.env.REACT_APP_REDIRECT_URI
                    })
                    : msalApp.acquireTokenPopup(accessTokenRequest);
            } else {
                console.error('Non-interactive error:', error)
            }
        });
	  };
}
