import React from "react";
import PropTypes from "prop-types";
import AuthProvider from "./services/AuthProvider";
import MiniDrawer from './MiniDrawer';	

import "./App.css";

class App extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired
    };
    
    render() {
        return (
            <div>
                <section>
                    {!this.props.account ? (
                        <button onClick={this.props.onSignIn}>Sign In</button>
                    ) : (
                        <div>
                            <button onClick={this.props.onSignOut}>
                                Sign Out 
                            </button>
                            Logged on user: {this.props.account.userName}
                        </div>
                    )}
                    {this.props.error && (
                        <p className="error">Error: {this.props.error}</p>
                    )}
                </section>
                <section className="data">
                    {this.props.account && (
	            	      <div className="App">
	            	        <MiniDrawer/>
	            	      </div>
                    )}
                </section>
            </div>
        );
    }
}

export default AuthProvider(App);