import APIService from '../services/APIService'

export default class ResponsesMSD {
  /* Get Cases */
  async getCase(caseId){
    // VAN-4864 | Added to retrieve case ID of first queue item of default queue from Dynamics
    var uri = 'getCaseId';
    var apiService = new APIService();
    var caseIdData = await apiService.getAPIData(uri);    
    // Uncomment caseID assignment in local dev if cert errors appear, browser devtools will show a call to it
    // which requires browsing to it in order to "magically" fix the issue. It can then be commented back out.
    // var caseId = 'a2b794c4-1e96-ea11-a812-001dd8017389';
    if (caseIdData && caseIdData['caseId'] != 'None') {
      caseId = caseIdData['caseId'];
    }   
    uri = `case/${caseId}`;
    var caseResponse = await apiService.getAPIData(uri);

	  return caseResponse;
  }

  /* get MaeraResponse */
  async getMaeraResponse(description,locale){
    console.log('locale value is:', locale)
    //if (locale == undefined || locale == '') {
    //  locale = 'India'
    //}
    var uri = `maera/${locale}`;
    var postData = description;
    
    var apiService = new APIService();
    var maeraResponse = await apiService.postAPIData(uri,postData);
	
	  return JSON.stringify(maeraResponse);
  }

  /* Submit cases */
  async submitCase(data){
    //var uri = `case/${data}`;
    var uri = 'case/submit'
    //var postData = 'Closed' //JSON.stringify(data);
    //var postData = JSON.stringify(data)
    var postData = data
    var apiService2 = new APIService()
    var caseresponse = await apiService2.postAPIData(uri, postData);
    return caseresponse;
  }

  /* Get Categories */
  async getCategories(){
    // VAN-4864 | Locales actually refer to queues here, for the dropdown to switch to other queues
    // Locales can be changed within a queue and categories are not actually used
    // If changing the "locales" key, make sure to change everywhere it's referenced
    let uri = 'getQueues';
    let apiService = new APIService();
    let queues = await apiService.getAPIData(uri);

    return {
      categories: [
        "Receipt",
        "Job Offers",
        "Profile Modifications",
        "Application Status",
        "Other",
        "All",
      ],
      queue: "Other",
      locales: queues,
      locale: queues[0],
    }
  }

  /* Update Queue */
  async updateQueue(queue){
    // VAN-4864 | Added API call to Dynamics API to update/switch queue from dropdown selection
    let uri = `updateQueue`;
    let postData = `{"queue": "${queue}"}`;
    let apiService = new APIService();
    let caseIdData = await apiService.postAPIData(uri, postData);
    var caseResponse = undefined;
    let caseId = 'None';    
    if (caseIdData && caseIdData['caseId'] != 'None') {
      caseId = caseIdData['caseId'];
    }
    console.log('caseId is: ', caseId)
    if (caseId != 'None') {
      uri = `case/${caseId}`;
      caseResponse = await apiService.getAPIData(uri);
      console.log(caseResponse)
    }
    return caseResponse;
  }

  // Adds call to the dynamics api to retrieve the 'queue name' of the queue assigned to the logged in User
  async getUserQueueName() {
    const uri = "getUserQueueName"
    const api = new APIService()
    const res = await api.getAPIData(uri)
    return res
  }

  /* Skip case */
  async skipCase(caseId){
    //var uri = `case/${data}`;
    var uri = 'case/skip'
    //var postData = 'Closed' //JSON.stringify(data);
    //var postData = JSON.stringify(data)
    var postData = caseId
    var apiService2 = new APIService();
    var caseResponse = await apiService2.postAPIData(uri,postData);
    /*let sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    await sleep(2000);
    var caseID = 200;

    uri = `case/${caseID}`;

    var apiService = new APIService();
    caseResponse = await apiService.getAPIData(uri);*/

	  return caseResponse;
  }

  /* Mark Spam */
  async markSpam(data){
    //var uri = `case/${data}`;
    var uri = 'case/markSpam'
    //var postData = 'Closed' //JSON.stringify(data);
    //var postData = JSON.stringify(data)
    var postData = data
    var apiService2 = new APIService();
    var caseResponse = await apiService2.postAPIData(uri,postData);
    /*let sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    await sleep(2000);
    var caseID = 200;

    uri = `case/${caseID}`;

    var apiService = new APIService();
    caseResponse = await apiService.getAPIData(uri);*/

	  return caseResponse;
  }
}
