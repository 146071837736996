import React from 'react'
import Select from 'react-select'
import DataField from './DataField.jsx'
import PropTypes from 'prop-types'

const StatusDropDown = (props) => {
    const options = props.options.map((x) => { return {label: x, value: x}});
    return <DataField labelName="Status">
      <Select name="statusDropList" options={options}
        value={props.selectedStatus} onChange={props.onSelected}
        clearable={false}/>
    </DataField>
}

StatusDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedStatus: PropTypes.string.isRequired,
  onSelected: PropTypes.func
}

export default StatusDropDown;
