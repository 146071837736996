import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';

const CGITheme = createMuiTheme({
  palette: {
    primary: {main: "#5DCBEC", light: lightBlue[100], dark: "#27b9e6"},
    secondary: {main: red[700], dark: red[900]}
  }
});

export default CGITheme;
