import React from 'react'
import PropTypes from 'prop-types'

const MultilineText = (props) => {
    if (!props.text) {
      return <span></span>
    } else {
      return (<span>
        {props.text.split('\n').map((line, index) => {
          return (line) ? <span key={index}>{line}<br/></span> : <br key={index}/>;
        })}
      </span>);
    }
}

MultilineText.propTypes = {
  text: PropTypes.string
}

export default MultilineText;
