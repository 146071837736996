import React from 'react';
import PropTypes from 'prop-types';

class CaseResponse extends React.Component{
  constructor(props){
    super(props);
    this.onInputChanged = this.onInputChanged.bind(this);
  }
  onInputChanged(event){
    this.props.handleDescriptionUpdate(event.target.value);
  }
  render(){
    return <textarea className="responseText case-response" value={this.props.publicResponse}
      name="caseResponse" rows="24" id="caseResponse"
      onChange={this.onInputChanged}/>;
  }
}

CaseResponse.propTypes = {
  handleDescriptionUpdate: PropTypes.func,
  publicResponse: PropTypes.string
}

export default CaseResponse;
