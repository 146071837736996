import React from 'react';
import PropTypes from 'prop-types';
import CaseDetails from './CaseDetails.jsx';
import MultilineText from './MultilineText';
import CheckBox from './CheckBox';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class ListHolder extends React.Component {
  constructor(props){
    super(props);
    this.onLocaleSelected = this.onLocaleSelected.bind(this);
    this.onLabelSelected = this.onLabelSelected.bind(this);
    this.onTopicSelected = this.onTopicSelected.bind(this);
    this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
    this.handleDescriptionUpdate = this.handleDescriptionUpdate.bind(this);
    this.updateComments = this.updateComments.bind(this);
    this.markSpam = this.markSpam.bind(this);
    this.skipCase = this.skipCase.bind(this);
    this.onStatusSelected = this.onStatusSelected.bind(this);
    this.onCopyToPR = this.onCopyToPR.bind(this);
    this.closeCase = this.closeCase.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleResponse = this.toggleResponse.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.updateExpandText = this.updateExpandText.bind(this);
    this.updateExpandResponse = this.updateExpandResponse.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);

    this.state = {locale : this.props.locale,
      selectedLabel : '',
      selectedTopic: {name: '', response: '', labels: []},
      fullText: false,
      closeDisabled: false,
      internalComments: this.props.case__c.Internal_Comments__c || '',
      publicResponse: this.props.case__c.Public_Response__c || '',
      status: this.props.case__c.Status__c || '',
      copiedTopic : {name: '', response: '', labels: []},
      copiedLocale : '',
      dropDownHidden: false,
      responseHidden: false,
      editHidden: false,
      userInfoHidden: true,
      responseSize: 20,
      infoY: 30,
      isResponseExpanded: false,
      isTextExpanded: false,
      caseId: this.props.case__c.Id || '',
      data: this.props.data
      }
  }

  updateResponseSize() {
    if (this.state.responseSize === 20) {
      this.setState({responseSize: 10, infoY: 20});
    } else {
      this.setState({responseSize: 20, infoY: 30});
    }
  }

  componentDidUpdate(nextProps){
    if (nextProps.case__c && nextProps.case__c.Id !== this.props.case__c.Id){
      this.setState({
        //caseId : this.props.case__c.Id,
        selectedLabel : '',
        selectedTopic: {name: '', response: '', labels: []},
        internalComments: this.props.case__c.Internal_Comments__c || '',
        publicResponse: this.props.case__c.Public_Response__c || '',
        status: this.props.case__c.Status__c || 'New',
        copiedTopic : {name: '', response: '', labels: []},
        copiedLocale : ''
      });
      let locale = this.props.locale;
      let firstLabel = this.props.data.labels[0] || '';
      let firstTopic = {name: '', response: '', labels: []};
      try {
        firstTopic = this.props.data.responses.find((x) => x.labels.includes(firstLabel) && x.locale === locale);
      } catch(err){}
      this.setState({selectedLabel: firstLabel, selectedTopic: firstTopic});
    }
  }

  componentDidMount(){
    let locale = this.props.locale;
    let firstLabel = this.props.data.labels[0] || '';
    let firstTopic = {name: '', response: '', labels: []};
    try {
      firstTopic = this.props.data.responses.find((x) => x.labels.includes(firstLabel) && x.locale === locale);
    } catch(err){}
    this.setState({selectedLabel: firstLabel, selectedTopic: firstTopic});
  }
  onLocaleSelected(option){
    if (option) {
    const selectedTopic = this.props.data.responses.find(
      (x) => x.labels.includes(this.state.selectedLabel) && x.locale === option.value) || {name: '', response: '', labels: []};
    this.setState({locale: option.value, selectedTopic});
    }
  }
  onLabelSelected(option){
    if (option) {
      const selectedTopic = this.props.data.responses.find(
        (x) => x.labels.includes(option.value) && x.locale === this.state.locale) || {name: '', response: '', labels: []};
      this.setState({selectedLabel: option.value, selectedTopic});
    }
  }
  onTopicSelected(option){
    if (option){
      const selectedTopic = this.props.data.responses.find(
        (x) => x.id === option.tid) || {name: '', response: '', labels: []};
      this.setState({selectedTopic});
    }
  }
  onStatusSelected(selected){
    this.setState({status : selected.value});
  }
  onInputChanged(filterText){
    this.setState({filterText});
  }
  onCheckboxChanged(fullText){
    this.setState({fullText});
  }

  hasStatusChanged(){
    return this.state.status !== this.props.case__c.Status__c;
  }

  getLocaleList(){
    const localeOptions = this.props.data.locale.map(
      (l) => {return {label: l, value: l}});
    return <Select value={this.state.locale} options={localeOptions} onChange={this.onLocaleSelected}
      clearable={false} placeholder="Select Locale"/>
  }

  getLabelList(){
    const labelOptions = this.props.data.labels.map(
      (l) => {return {label: l.replace(/__label__/g, '').replace(/_/g, ' '), value: l}});
    return <Select id="select-label"
            value={this.state.selectedLabel}
            options={labelOptions}
            onChange={this.onLabelSelected}
            clearable={false}/>
  }

  getTopicList(){
    if (!this.props.data.responses || !this.state.selectedLabel) return null;
    const topicOptions = this.props.data.responses.filter(
      (r) => r.labels.includes(this.state.selectedLabel) && r.locale === this.state.locale).map(
      (t) => {return {label: t.name, value: t.name, tid:t.id}});
    return <Select id="select-topic"
            value={this.state.selectedTopic && this.state.selectedTopic.name}
            options={topicOptions}
            onChange={this.onTopicSelected}
            clearable={false}
            placeholder="Select Response"/>
  }

  getAllTopicsList(){
    if (!this.props.data.responses)return null;
    const topicOptions = this.props.data.responses.map(
      (t) => {return {label: t.name, value: t.name, tid:t.id}});
    return <Select id="all-topics"
            value={this.state.selectedTopic && this.state.selectedTopic.name}
            options={topicOptions}
            onChange={this.onTopicSelected}
            clearable={false}/>
  }

  handleDescriptionUpdate(text){
    this.setState({publicResponse: text});
  }

  onCopyToPR(text){
    this.scrollDown();
    this.setState({publicResponse: this.state.selectedTopic && this.state.selectedTopic.response,
          copiedTopic: this.state.selectedTopic,
          copiedLocale: this.state.locale});
  }

  markSpam(){
    this.setState(
      {internalComments: "Spam", publicResponse : '', status : 'Closed', copiedTopic: {name: '', response: '', labels: []}},
      ()=>this.closeCase('Closed'));
  }

  async skipCase(){
    this.setState({closeDisabled: true});
    let responseMap = {id: this.props.case__c.Id,
      publicResponse: this.state.publicResponse,
      internalComments: this.state.internalComments,
      topic: this.state.copiedTopic.name,
      locale: this.state.copiedLocale,
      version: this.state.version
    }
    var self = this;
    this.props.skipCase(responseMap).then(() => self.setState({closeDisabled : false}));
  }

  async closeCase(status){
    this.setState({closeDisabled : true})
    let maeraLabels = this.props.data.labels.reduce((x,y) => x + ',' + y);
    let predicted = this.state.publicResponse === '' ||
      this.props.data.labels.some((x) => this.state.copiedTopic.labels.includes(x)).toString();
    let responseMap = {id: this.props.case__c.Id,
          publicResponse: this.state.publicResponse,
          status: status || this.state.status,
          internalComments: this.state.internalComments,
          predicted: predicted,
          maeraLabels: maeraLabels,
          topic: this.state.copiedTopic.name,
          locale: this.state.copiedLocale,
          version: this.state.version,
          queueItemId: this.props.case__c.Queue_Item_ID__c,
          customerId: this.props.case__c.Customer_ID__c
        }
    var self = this;
    this.props.closeCase(responseMap).then(() =>
      self.setState({closeDisabled : false}));
  }

  toggleDropdown() {
    this.setState(function(prevState) {
      return {dropDownHidden: !prevState.dropDownHidden};
    });
  }

  toggleResponse() {
    this.setState(function(prevState) {
      return {responseHidden: !prevState.responseHidden};
    });
  }

  toggleEdit() {
    this.setState(function(prevState) {
      return {editHidden: !prevState.editHidden};
    });
  }

  toggleInfo() {
    this.setState(function(prevState) {
      return {userInfoHidden: !prevState.userInfoHidden};
    });
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  updateComments(internalComments){
    this.setState({internalComments});
  }
  updateExpandResponse() {
    this.setState(function(prevState) {
      return {isResponseExpanded: !prevState.isResponseExpanded};
    });
  }

  updateExpandText() {
    this.setState(function(prevState) {
      return {isTextExpanded: !prevState.isTextExpanded};
    });
  }

  scrollDown() {
    this.setState({isResponseExpanded: true});
    window.scrollTo(0,document.querySelector(".editInner").scrollHeight);
  }

  render(){
    // var layout = [
    //   {i: 'dropDownDiv', x: 0, y: 0, w: 20, h: 5, minH: 5},
    //   {i: 'infoDiv', x: 0, y: this.state.infoY, w: 10, h: 10, minH: 10},
    //   {i: 'responseDiv', x: 0, y: 9, w: 10, h: this.state.responseSize, minH: 9},
    //   {i: 'editDiv', x: 10, y: 0, w: 10, h: 20, minH: 20}
    // ];

    const dropDownOptions = ['In Progress', 'On Hold', 'Waiting for Details',
      'Researching', 'Escalate to Call Center', 'Escalate to PMO', 'Escalate to Post'];
    const checkBoxStyle = {label: {class: 'field-label'},  div: {}, checkbox: {}}

    return (
      <div className="gridLayout">

        <Paper className="dropDownDiv" hidden={this.state.dropDownHidden} key="dropDownDiv">
          <div className="dropDownInner">
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Paper className="dropDowns">
                  {this.getLocaleList()}
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className="dropDowns">
                  {this.getLabelList()}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className="dropDowns">
                  {this.getTopicList()}
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs>
                <Paper className="dropDowns">
                  {this.getAllTopicsList()}
                </Paper>
              </Grid>
            </Grid>
            <div>
            {this.isResponseExpanded}
            </div>
            <br/>
            <div className="dropDownExtra">
              <CheckBox onCheck={this.onCheckboxChanged} checked={this.state.fullText} text="Include response text &nbsp;"
                  style={checkBoxStyle}/>
              <div>
                <Button className="dropDownButtons" onClick={() => this.onCopyToPR(this.state.selectedResponse)}>
                  Copy to Public Response
                </Button>
                <Button className="dropDownButtons" disabled={this.state.closeDisabled} onClick={() => this.closeCase(this.state.status)}>Save</Button>
                <Button className="dropDownButtons" disabled={this.state.closeDisabled} onClick={() => this.closeCase('Closed')}>Close</Button>
                <Button className="dropDownButtons" disabled={this.state.closeDisabled} onClick={this.skipCase}>Skip</Button>
                <Button className="dropDownButtons" disabled={this.state.closeDisabled} color="secondary" onClick={this.markSpam}>Mark Spam</Button>
              </div>
            </div>
          </div>
        </Paper>


        <div className="rows">
          <Paper className="responseDiv" hidden={this.state.responseHidden} key="responseDiv">
            <div className="responseInner">
              <MultilineText text={this.state.selectedTopic && this.state.selectedTopic.response}/>
            </div>
          </Paper>

          <Paper className="editDiv" hidden={this.state.editHidden} key="editDiv">
            <div className="editInner">
              <CaseDetails caseId={this.props.case__c.Id} 
                ticketNumber={this.props.case__c.Ticket_Number__c}
                contactId={this.props.case__c.Contact__c}
                name={this.props.case__c.Contact__r.Name}
                selectedStatus={this.state.status}
                options={dropDownOptions}
                country={this.props.case__c.Country_Account_Name__c}
                dtOpened={new Date(this.props.case__c.Date_Time_Opened__c).toDateString()}
                internalComments={this.state.internalComments}
                title={this.props.case__c.Title__c}
                caseDescription={this.props.case__c.Description__c}
                publicResponse={this.state.publicResponse}
                updateComments={this.updateComments}
                handleDescriptionUpdate={this.handleDescriptionUpdate}
                onSelected={this.onStatusSelected}
                responseExpanded={this.updateExpandResponse}
                textExpanded={this.updateExpandText}/>
            </div>
          </Paper>
        </div>
    </div>)
  }
}

ListHolder.propTypes = {
  case__c: PropTypes.object,
  data: PropTypes.object,
  locales: PropTypes.string,
  closeCase: PropTypes.func,
  skipCase: PropTypes.func
}

export default ListHolder;
 