import React from 'react';
import PropTypes from 'prop-types';

const DataField = (props) => {
    return <React.Fragment>
      <td className="field-label">{props.labelName}</td>
      <td className="field-data" colSpan={props.colSpan}>{props.children}</td>
    </React.Fragment>
}

DataField.propTypes = {
  labelName: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
  children: PropTypes.any
}

export default DataField;
