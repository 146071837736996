import ResponsesMSD from "./ResponsesMSD";

export default class Responses {
  /* TODO - Make it agnostic to any client*/
  
  getCase = (caseId) => new ResponsesMSD().getCase(caseId);
  submitCase = (data) => new ResponsesMSD().submitCase(data);
  getMaeraResponse = (description, locale) => new ResponsesMSD().getMaeraResponse(description, locale);
  getCategories = () => new ResponsesMSD().getCategories();
  skipCase = (data) => new ResponsesMSD().skipCase(data);
  markSpam = (caseId) => new ResponsesMSD().markSpam(caseId);
  updateQueue = (queue) => new ResponsesMSD().updateQueue(queue);
  updateLocale = (locale) => new ResponsesMSD().updateLocale(locale);
  getUserQueueName = () => new ResponsesMSD().getUserQueueName();
}