import React from 'react';
import PropTypes from 'prop-types';

class CheckBox extends React.Component {
  constructor(props){
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }
  handleCheck(e){
    this.props.onCheck(e.target.checked);
  }
  render(){
    const style = this.props.style || {label: {}, checkbox: {}, div: {}};

    return (<div className={style.div.class} style={style.div.css}>
        
        </div>);
  }
}

CheckBox.propTypes = {
  onCheck: PropTypes.func,
  style: PropTypes.shape(
    {label: PropTypes.object, checkbox: PropTypes.object, div: PropTypes.object}),
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string,
}

export default CheckBox;
