import React from 'react';
/*import ContactLine from './ContactLine.jsx';*/
import StatusDropDown from './StatusDropDown.jsx';
import DataField from './DataField.jsx';
import MultilineText from './MultilineText.jsx';
import CaseResponse from './CaseResponse.jsx';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class CaseDetails extends React.Component {
  constructor(props){
    super(props);
    this.updateComments = this.updateComments.bind(this);
    this.state = {
      expandResponse : true,
    }
  }
  updateComments(e){
    this.props.updateComments(e.target.value);
  }
updateResponse = () => {
  this.props.responseExpanded.bind(this)
  this.setState(function(prevState) {
    return {expandResponse: !prevState.expandResponse};
  });
}


render() {
  return (<div>
  <table className="case-table">
    <tbody>
      <tr>
        <DataField labelName="Ticket Number">{this.props.ticketNumber}</DataField>
      </tr>
      <tr>
        {/*<ContactLine contactId={this.props.contactId} name={this.props.name}/>*/}
        {/*<ContactLine contactId={this.props.name} name={this.props.name}/>*/}
        <DataField labelName="Contact">{this.props.contactId}</DataField>
        <StatusDropDown options={this.props.options} selectedStatus={this.props.selectedStatus} onSelected={this.props.onSelected}/>
      </tr>
      <tr>
        <DataField labelName="Date/Time Opened">{this.props.dtOpened}</DataField>
        <DataField labelName="Country">{this.props.country}</DataField>
      </tr>
      <tr>
        <DataField labelName="Title" colSpan={3}>
          <MultilineText name="title" text={this.props.title}/>
        </DataField>
      </tr>
      <tr>
        <DataField labelName="Description" colSpan={3}>
          <MultilineText name="description" text={this.props.caseDescription}/>
        </DataField>
      </tr>
    </tbody>
    </table>
    <br/>
    <Accordion className="responseExpand" onChange={this.props.textExpanded.bind(this)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Internal Comments</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="responseText">
          <textarea className="responseText" value={this.props.internalComments} onChange={this.updateComments}
            name="internalComments" rows="2" id="internalComments"/>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="responseExpand" expanded={this.state.expandResponse} onChange={this.updateResponse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Public Response</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="responseText">
          <CaseResponse name="caseResponse" publicResponse={this.props.publicResponse}
            handleDescriptionUpdate={this.props.handleDescriptionUpdate} class="responseText case-response"/>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion className="responseExpand" onChange={this.props.textExpanded.bind(this)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Case History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="responseText">
          <div className="responseText"
          name="caseHistory" id="caseHistory">
          <p><a href={process.env.REACT_APP_CASE_LINK_URL + this.props.caseId} target="_blank" rel="noopener noreferrer">Click here to go to Case History</a></p>
          </div>
      </Typography>
      </AccordionDetails>
    </Accordion>
  </div>)
}
}

CaseDetails.propTypes = {
  caseId: PropTypes.string,
  ticketNumber: PropTypes.string,
  contactId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  dtOpened: PropTypes.string,
  country: PropTypes.string,
  title: PropTypes.string,
  caseDescription: PropTypes.string,
  internalComments: PropTypes.string,
  publicResponse: PropTypes.string,
  handleDescriptionUpdate: PropTypes.func,
  updateComments: PropTypes.func,
  responseExpanded: PropTypes.func,
  textExpanded: PropTypes.func,
}

export default CaseDetails;
